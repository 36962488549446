import React, {useState} from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import BlueCard from "./BlueCard";
import RedCard from "./RedCard";
import YellowCard from "./YellowCard";
import GreenCard from "./GreenCard";
import ImaCardsAll from "./ImaCardsAll";
import {ArrowForward} from "@mui/icons-material";

const ImaColorInfo = ({isOpen, imageUrl, onClose, customStyle}) => {

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    let title;
    if (!imageUrl) {
        return null;
    }
    const lowerImageUrl = imageUrl.toLowerCase();
    console.log(imageUrl)
    if (lowerImageUrl.includes('red')) {
        title = 'Red';
    } else if (lowerImageUrl.includes('blue')) {
        title = 'Blue';
    } else if (lowerImageUrl.includes('green')) {
        title = 'Green';
    } else if (lowerImageUrl.includes('yellow')) {
        title = 'Yellow';
    } else {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg" aria-labelledby="image-modal-title"
                PaperProps={{
                    style: {
                        border: `1px solid ${title}`, // Set the border color of the dialog
                        borderRadius: '20px', // Optional: Add border radius
                        maxHeight:'100%',
                        backgroundColor:'transparent'
                    },
                }}>

            <DialogTitle id="image-modal-title" style={{ maxHeight:'20px', color:'aliceblue', fontWeight: 'bold', backgroundColor: "grey"}}>iMA
                High {title}</DialogTitle>
            <DialogContent>

                    {title === "Blue" && <BlueCard/>}
                    {title === "Red" && <RedCard/>}
                    {title === "Yellow" && <YellowCard/>}
                    {title === "Green" && <GreenCard/>}

            </DialogContent>
            <DialogActions>
                <ImaCardsAll open={dialogOpen} handleClose={handleDialogClose} />
                <Button onClick={onClose} style={{ position: 'absolute',
                    top: '-10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '40px',}} variant={customStyle}>
                    &times;
                </Button>
                <Button onClick={handleDialogOpen} style={{maxHeight:'40px'}} variant={customStyle}  >Click to learn more about the other iMA colours <ArrowForward fontSize="inherit" /></Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImaColorInfo;